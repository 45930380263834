<template>
  <div id="user-profile">
    <b-row>
      <b-col lg="12" cols="12" order="2" order-lg="2">
        <b-card class="profile-header mb-2" body-class="p-0">
          <b-card-body>
            <div class="position-relative">
              <b-row>
                <b-col lg="2" cols="2" order="1" order-lg="1">
                  <div class="profile-img">
                    <b-img
                      :src="selectedUser.profileImage"
                      style="width: 126px; height: 126px; object-fit: contain"
                      rounded
                      fluid
                      alt="profile photo"
                    />
                  </div>
                </b-col>
                <b-col lg="4" cols="4" order="1" order-lg="1">
                  <div class="profile-title">
                    <h2 class="text-black">
                      {{ selectedUser.firstName }} {{ selectedUser.lastName }}
                    </h2>
                    <span> Telefon Numarası : {{ selectedUser.gsmNumber }}</span
                    ><br />
                    <span> E-Mail Hesabı : {{ selectedUser.email }}</span>
                  </div>
                </b-col>
                <b-col lg="6" cols="6" order="1" order-lg="1">
                  <b-row class="mb-2">
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                      <label>Ders</label>
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="filteredLesson"
                        :options="lessons"
                        class="w-100"
                        :getOptionLabel="(option) => option.lesson.name"
                        :reduce="(val) => val"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                      <label>Alt Ders</label>
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="filteredChildLesson"
                        :options="childLessons"
                        class="w-100"
                        :reduce="(val) => val"
                        :getOptionLabel="(option) => option.name"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="position-relative" style="justify-content: center">
                <b-tabs pills class="profile-tabs">
                  <template #tabs-start>
                    <b-nav-item
                      role="presentation"
                      :active="selectedMenu == 0"
                      class="font-weight-bold"
                      @click="selectedMenu = 0"
                    >
                      <span class="d-none d-md-block">▶ Videolar</span>
                      <feather-icon icon="RssIcon" class="d-block d-md-none" />
                    </b-nav-item>
                    <b-nav-item
                      role="presentation"
                      :active="selectedMenu == 1"
                      class="font-weight-bold"
                      @click="selectedMenu = 1"
                    >
                      <span class="d-none d-md-block">📃 Testler</span>
                      <feather-icon icon="InfoIcon" class="d-block d-md-none" />
                    </b-nav-item>
                    <b-nav-item
                      role="presentation"
                      :active="selectedMenu == 2"
                      class="font-weight-bold"
                      @click="selectedMenu = 2"
                    >
                      <span class="d-none d-md-block">💣 Eksikler</span>
                      <feather-icon
                        icon="ImageIcon"
                        class="d-block d-md-none"
                      />
                    </b-nav-item>
                    <b-nav-item
                      role="presentation"
                      :active="selectedMenu == 3"
                      class="font-weight-bold"
                      @click="selectedMenu = 3"
                    >
                      <span class="d-none d-md-block">💣 Devamsızlık</span>
                      <feather-icon
                        icon="ImageIcon"
                        class="d-block d-md-none"
                      />
                    </b-nav-item>
                  </template>
                </b-tabs>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
        <section id="profile-info">
          <b-card
            class="profile-header mb-2"
            body-class="p-0"
            v-if="selectedMenu == 0"
          >
            <b-card-body>
              <b-row>
                <b-col lg="12" cols="12" order="1" order-lg="2">
                  <app-timeline>
                    <app-timeline-item
                      variant="success"
                      v-for="video in videos"
                      :key="video.video.id"
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>{{ video.video.name }}</h6>
                        <small class="text-muted">{{ video.firstDate }}</small>
                      </div>
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column
                          justify-content-between
                          align-items-start
                        "
                      >
                        <div style="width: 100%" class="mb-1 mb-sm-0">
                          <b-progress
                            key="success"
                            class="progress-bar-success"
                          >
                            <b-progress-bar
                              animated
                              :value="video.percent"
                              variant="success"
                            >
                              <span
                                >İzleme Süresi:
                                <strong>{{
                                  new Date(video.time * 1000)
                                    .toISOString()
                                    .substring(14, 19)
                                }}</strong></span
                              >
                            </b-progress-bar>
                          </b-progress>
                        </div>
                      </div>
                    </app-timeline-item>
                  </app-timeline>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card
            class="profile-header mb-2"
            body-class="p-0"
            v-if="selectedMenu == 1"
          >
            <b-card-body>
              <b-row>
                <b-col lg="12" cols="12" order="1" order-lg="2">
                  <app-timeline>
                    <app-timeline-item
                      :variant="getTestVariant(test.status)"
                      v-for="test in tests"
                      :key="test.testId"
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>{{ test.title }}</h6>
                        <small class="text-muted">{{ test.startDate }}</small>
                      </div>
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column
                          justify-content-between
                          align-items-start
                        "
                      >
                        <!-- 1st Col -->
                        <div class="mb-1 mb-sm-0 text-center">
                          <span class="text-muted mb-50">D Y B</span>
                          <div>
                            <b-avatar
                              :text="test.correct.toString()"
                              class="mr-50"
                              size="24"
                              variant="light-success"
                            />
                            <b-avatar
                              :text="test.wrong.toString()"
                              class="mr-50"
                              size="24"
                              variant="light-danger"
                            />
                            <b-avatar
                              :text="test.empty.toString()"
                              class="mr-50"
                              size="24"
                              variant="light-info"
                            />
                          </div>
                        </div>
                        <!-- 2nd Col -->
                        <div class="mb-1 mb-sm-0 text-center">
                          <span class="text-muted mb-50 d-block">Puan</span>
                          <span>{{ parseFloat(test.point).toFixed(2) }}</span>
                        </div>
                        <!-- 3rd Col -->
                        <div class="mb-1 mb-sm-0 text-center">
                          <span class="text-muted mb-50 d-block"
                            >Bitiş Tarihi</span
                          >
                          <span>{{ test.endDate }}</span>
                        </div>
                      </div>
                    </app-timeline-item>
                  </app-timeline>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card
            class="profile-header mb-2"
            body-class="p-0"
            v-if="selectedMenu == 2"
          >
            <b-card-body>
              <b-row>
                <b-col lg="12" cols="12" order="1" order-lg="2">
                  <app-timeline>
                    <app-timeline-item
                      :variant="lack.isDelete == true ? 'success' : 'danger'"
                      v-for="lack in lacks"
                      :key="lack.gainId"
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>{{ lack.title }}</h6>
                        <small class="text-muted">{{ lack.createdAt }}</small>
                      </div>
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column
                          justify-content-between
                          align-items-start
                        "
                      >
                        <!-- 1st Col -->
                        <div class="mb-1 mb-sm-0 text-center">
                          <span class="text-muted mb-50">
                            {{lack.lesson+" > "+lack.childLesson+" > "+lack.topic+" >
                                                        "+lack.videoGroup}}
                          </span>
                        </div>
                        <div class="mb-1 mb-sm-0 text-center">
                          <span class="text-muted mb-50">
                            {{ "Eksik Giderme Tarihi : " + lack.deletedAt }}
                          </span>
                        </div>
                      </div>
                    </app-timeline-item>
                  </app-timeline>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card
            class="profile-header mb-2"
            body-class="p-0"
            v-if="selectedMenu == 3"
          >
            <b-card-body>
              <b-row>
                <b-col lg="12" cols="12" order="1" order-lg="2">
                  <div>
                    <h3>
                      Toplam
                      <span>{{
                        stylabus.filter((x) => x.isActive == false).length
                      }}</span>
                      Derse Girmedi
                    </h3>
                    <h3>
                      Toplam
                      <span>{{
                        stylabus.filter((x) => x.isActive == true).length
                      }}</span>
                      Derse Girdi
                    </h3>
                    <br />
                  </div>
                  <app-timeline>
                    <app-timeline-item
                      :variant="sty.isActive == true ? 'success' : 'danger'"
                      v-for="sty in stylabus"
                      :key="sty.id"
                    >
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>{{ sty.title }}</h6>
                        <small class="text-muted">{{
                          sty.day +
                          "." +
                          (sty.month > 9 ? sty.month : "0" + sty.month) +
                          "." +
                          sty.year
                        }}</small>
                      </div>
                    </app-timeline-item>
                  </app-timeline>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </section>
        <b-overlay :show="loading" no-wrap> </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTable,
  BAvatar,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
export default {
  components: {
    BOverlay,
    BProgressBar,
    BProgress,
    BTable,
    BAvatar,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
  },
  data() {
    return {
      loading: false,
      selectedMenu: 0,
      questionFields: [
        { key: "fullName", label: "Öğrenci Adı Soyadı" },
        { key: "action", label: "İşlem" },
      ],
      selectedUser: {},
      videos: [],
      tests: [],
      lacks: [],
      stylabus: [],
      lessons: [],
      filteredLesson: null,
      childLessons: [],
      filteredChildLesson: null,
    };
  },
  async created() {
    this.loading = true;
    var user = await this.$http.get("Report/User/" + this.$route.params.uid);
    this.getReport(user.data.data);
    this.getLesson();
    this.loading = false;
  },
  watch: {
    filteredLesson: function (val) {
      this.getChildLesson();
      //this.refleshData();
    },
    filteredChildLesson: function (val) {
      this.refleshData();
    },
  },
  methods: {
    async getLesson() {
      var lesson = await this.$http.get("Teacher/Lessons");
      this.lessons = lesson.data.data;
    },
    async getChildLesson() {
      var lesson = await this.$http.get(
        "Teacher/ChildLessons/" +
          this.filteredLesson.lesson.id +
          "/" +
          this.filteredLesson.class.id
      );
      this.childLessons = lesson.data.data;
    },
    async getReport(user) {
      this.selectedUser = user;
      this.refleshData();
    },
    async refleshData() {
      this.loading = true;
      await this.getVideo();
      await this.getTest();
      await this.getLacks();
      await this.getStylabus();
      this.loading = false;
    },
    async getVideo() {
      var videos = await this.$http.post(
        "Report/VideoWatching/" + this.selectedUser.id,
        {
          lessonId:
            this.filteredLesson == null
              ? "00000000-0000-0000-0000-000000000000"
              : this.filteredLesson.lesson.id,
          classId:
            this.filteredLesson == null
              ? "00000000-0000-0000-0000-000000000000"
              : this.filteredLesson.class.id,
          childLessonId:
            this.filteredChildLesson == null
              ? "00000000-0000-0000-0000-000000000000"
              : this.filteredChildLesson.id,
          topicId: "00000000-0000-0000-0000-000000000000",
          videoGroupId: "00000000-0000-0000-0000-000000000000",
          videoId: "00000000-0000-0000-0000-000000000000",
        }
      );
      this.videos = videos.data.data;
    },
    async getTest() {
      var tests = await this.$http.post("Report/Test/" + this.selectedUser.id, {
        lessonId:
          this.filteredLesson == null
            ? "00000000-0000-0000-0000-000000000000"
            : this.filteredLesson.lesson.id,
        classId:
          this.filteredLesson == null
            ? "00000000-0000-0000-0000-000000000000"
            : this.filteredLesson.class.id,
        childLessonId:
          this.filteredChildLesson == null
            ? "00000000-0000-0000-0000-000000000000"
            : this.filteredChildLesson.id,
        topicId: "00000000-0000-0000-0000-000000000000",
        videoGroupId: "00000000-0000-0000-0000-000000000000",
        videoId: "00000000-0000-0000-0000-000000000000",
      });
      this.tests = tests.data.data;
    },
    async getLacks() {
      var lacks = await this.$http.post(
        "Report/Lacks/" + this.selectedUser.id,
        {
          lessonId:
            this.filteredLesson == null
              ? "00000000-0000-0000-0000-000000000000"
              : this.filteredLesson.lesson.id,
          classId:
            this.filteredLesson == null
              ? "00000000-0000-0000-0000-000000000000"
              : this.filteredLesson.class.id,
          childLessonId:
            this.filteredChildLesson == null
              ? "00000000-0000-0000-0000-000000000000"
              : this.filteredChildLesson.id,
          topicId: "00000000-0000-0000-0000-000000000000",
          videoGroupId: "00000000-0000-0000-0000-000000000000",
          videoId: "00000000-0000-0000-0000-000000000000",
        }
      );
      this.lacks = lacks.data.data;
    },
     async getStylabus() {
      var stylabus = await this.$http.get(
        "Report/StudentDiscontinuity/" + this.selectedUser.id
      );
      this.stylabus = stylabus.data.data;
    },
    getTestVariant(status) {
      switch (status) {
        case "HasResulted":
          return "success";
        case "Waiting":
          return "info";
        case "Processing":
          return "warning";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
